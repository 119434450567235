import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const BannerAlertBox = makeShortcode("BannerAlertBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <BannerAlertBox variant="warning" style={{
      marginBottom: '2rem'
    }} mdxType="BannerAlertBox">
    This deprecated service was created to handle the lack of persistent
    serviceJourney ids received from the data providers. The application was an
    intermediate solution and any usage should be discontinued.
    </BannerAlertBox>
    <h1 {...{
      "id": "datedservicejourney"
    }}>{`DatedServiceJourney`}</h1>
    <p>{`Created during import and splits the servicejourney into one entity for each day and creating an identifier for this entity. Uses serviceJourneyId + date to check if new entities are needed.`}</p>
    <h1 {...{
      "id": "originaldatedservicejourney"
    }}>{`OriginalDatedServiceJourney`}</h1>
    <p>{`Also created during import and checks privateCode + date + datedServiceJourneyId to determine if a new entity or a new entry within the originalDatedServiceJourney is created.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      